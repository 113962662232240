import { useEffect, useContext, useRef } from 'react'
import { GlobalContext } from 'contexts'
import { removeOverlay } from 'services'
import { noop } from 'utils'

export default function Overlay() {
  const overlay = useRef(null)
  const {
    dispatch,
    state: { overlayActive, overlayLocked, Modal },
  } = useContext(GlobalContext)

  useEffect(() => {
    const bodyClass = document.querySelector('body')!.classList
    if (overlayActive) {
      bodyClass.add('lock')
    } else {
      bodyClass.remove('lock')
    }
  }, [overlayActive])

  useEffect(() => {
    const onClose = Modal?.onClose
    return onClose ? () => onClose?.() : noop
  }, [Modal])

  return (
    <>
      <div
        ref={overlay}
        className={`modal-overlay${overlayActive ? ' active' : ''}`}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
        onClick={(e) => {
          if (e.target === overlay.current && !overlayLocked) {
            dispatch(removeOverlay())
          }
        }}
      >
        {Modal?.modal && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1,
            }}
          >
            <Modal.modal />
          </div>
        )}
      </div>
    </>
  )
}
