import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useState, useEffect, useRef, useContext } from 'react'
import Header from 'components/Header'
import Footer from 'components/Footer'
import Cart from '@components/cart/Cart'
import Overlay from 'components/Overlay'
import { useWindowLocation, useWindowSize } from 'hooks'
import { GlobalContext } from 'contexts'
import { removeOverlay } from 'services'
import { useFacebook, useTaboola, useKlaviyo } from 'services/tracking'
import { UserContext } from 'contexts/user-context'
import { sha256 } from 'js-sha256'
import { loadPodscribe } from 'public/scripts/podscribe'

declare const window: any

const Layout = ({ children }) => {
  const app = useRef(null)
  const [title, setTitle] = useState('Good Ranchers')
  const router = useRouter()
  const windowLocation = useWindowLocation()
  const { dispatch } = useContext(GlobalContext)
  const windowSize = useWindowSize()
  const pageWidth = windowSize?.width || 0
  var deviceType = pageWidth > 768 ? 'desktop' : 'mobile'
  const {
    state: { user },
  } = useContext(UserContext)
  const [userEmail, setUserEmail] = useState(user?.email || '')
  const { state } = useContext(UserContext)
  var deviceType = pageWidth > 768 ? 'desktop' : 'mobile'
  let emailEncrypted = sha256(user ? user.email : '')

  const getBackgroundColor = () => {
    if (
      router.pathname.includes('product') ||
      router.pathname.includes('account') ||
      router.pathname.includes('merch') ||
      router.pathname === '/login' ||
      router.pathname === '/shop' ||
      router.pathname === '/about' ||
      router.pathname === '/ambassadors' ||
      router.pathname === '/rewards' ||
      router.pathname === '/gifting' ||
      router.pathname === '/rb-components/RBTesting' ||
      router.pathname === '/privacy-policy' ||
      router.pathname === '/cart' ||
      router.pathname === '/packaging' ||
      router.pathname === '/' ||
      router.pathname === '/babylonbeef' ||
      router.pathname === '/merch' ||
      router.pathname === '/next-order-addons' ||
      router.pathname === '/addons' ||
      router.pathname === '/terms-of-use' ||
      router.pathname.includes('gifts')
    ) {
      return 'bg-cream-300'
    }
    if (router.pathname.includes('/march-meatness')) {
      return 'bg-cream-800'
    } else {
      return 'bg-white'
    }
  }

  useFacebook()
  useTaboola()
  useKlaviyo()

  useEffect(() => {
    const closeDrawer = () => dispatch(removeOverlay())
    router.events.on('beforeHistoryChange', closeDrawer)

    return () => router.events.off('beforeHistoryChange', closeDrawer)
  })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const x = `${(windowLocation?.length > 1 ? windowLocation : 'Home')
        .replace(/product\//i, '')
        .replace(/gifts\//i, '')
        .replace(/blog\//i, '')
        .replace(/merch\//i, '')
        .replace(/independenceday/i, 'Independence Day')
        .replace(/\//g, '')
        .replace(/-_/g, ' ')
        .split('-')
        .map(
          (word: String) => word.substr(0, 1).toUpperCase() + word.substring(1)
        )
        .join(' ')} - Good Ranchers`
      setTitle(x)
    }
  }, [windowLocation])

  {
    process.env.NODE_ENV === 'production' &&
      useEffect(() => {
        if (user && user.email) {
          window.criteo_q = window.criteo_q || []
          window.criteo_q.push(
            {
              event: 'setAccount',
              account: process.env.NEXT_PUBLIC_CRITEO_ACCOUNT,
            },
            {
              event: 'setEmail',
              email: user.email ? emailEncrypted : '',
              hash_method: 'sha256',
            },
            { event: 'setSiteType', type: deviceType },
            { event: 'viewPage' }
          )
          window.twq('event', 'tw-of5ov-of8gy', {
            email_address: user.email,
          })
          setUserEmail(user.email)
        } else {
          window.criteo_q = window.criteo_q || []
          window.criteo_q.push(
            {
              event: 'setAccount',
              account: process.env.NEXT_PUBLIC_CRITEO_ACCOUNT,
            },
            {
              event: 'setEmail',
              email: '',
              hash_method: '',
            },
            { event: 'setSiteType', type: deviceType },
            { event: 'viewPage' }
          )
          window.twq('event', 'tw-of5ov-of8gy', {
            email_address: '',
          })
        }
        setUserEmail('')
        loadPodscribe()
        window.pintrk('track', 'pagevisit')
      }, [router.pathname, user])
  }
  function getPaddingBottom(state, pageWidth, router) {
    if (
      router.pathname === '/independenceday' ||
      router.pathname.includes('gifts')
    ) {
      return 'pb-[150px] bg-cream-300'
    }

    if (!state.user) {
      return pageWidth > 768 ? 'pb-[23rem]' : 'pb-[13rem]'
    }

    return 'pb-[0rem]'
  }

  function getMarginTop(pageWidth) {
    return pageWidth === 768 ? '!mt-[74px]' : 'mt-[74px] md:mt-[120px]'
  }

  const layoutClass = `${getBackgroundColor()} flex flex-col flex-grow ${getMarginTop(
    pageWidth
  )} ${getPaddingBottom(state, pageWidth, router)}`

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="format-detection" content="telephone=no" />
        <link rel="icon" href="/favicon.ico" />
        <script src="/scripts/jquery-3.3.1.min.js" async={true} />
        <script src="/scripts/bootstrap.min.js" defer={true} />
        {process.env.NODE_ENV === 'production' && (
          <script src="/scripts/podscribe.js" defer={true} />
        )}
      </Head>
      <Header />
      <Cart />
      <div ref={app} className={layoutClass}>
        {children}
      </div>
      <Footer />
      <Overlay />
    </>
  )
}

export default Layout