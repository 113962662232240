export function loadPodscribe(w = window, d = document) {
    var id = 'podscribe-capture',
        n = 'script';
    var e = d.createElement(n);
    e.id = id;
    e.async = true;
    e.src = 'https://d34r8q7sht0t9k.cloudfront.net/tag.js';
    var s = d.getElementsByTagName(n)[0];
    s.parentNode.insertBefore(e, s);

    e.addEventListener('load', function() {
        w.podscribe('init', {
            user_id: '0d2e093e-4b80-4b47-b890-7a84960409c1',
            advertiser: 'goodranchers',
        });
        w.podscribe('view');
    });
}
